export const isLocalhost = () => window.location.hostname === "localhost";

export const isEnergyDemo = () =>
    window.location.hostname.includes("energy-demo");

export const getDashboardApiUrl = () => {
    return `${window.location.origin}/api/graphql`;
};

export const getAuthApiUrl = () => {
    const loginUrl = window.location.origin;
    return `https://auth.${loginUrl
        .split(".")
        .slice(1)
        .join(".")}/api/v1/email`;
};
